import team1 from "../assets/team-1.jpg";
import team2 from "../assets/team-2.jpg";
import team3 from "../assets/team-3.jpg";
import team4 from "../assets/team-4.jpg";
import team5 from "../assets/team-5.jpg";
import team6 from "../assets/team-6.jpg";

export const ourTeamData = [
  {
    id: 1,
    name: "Monica",
    status: "Trainer",

    image: team1,
  },
  {
    id: 2,
    name: "Shaks",
    status: "Trainer",

    image: team2,
  },
  {
    id: 3,
    name: "Jarule",
    status: "Trainer",

    image: team3,
  },
  {
    id: 4,
    name: "Modip",
    status: "Trainer",

    image: team4,
  },
  {
    id: 5,
    name: "Monica",
    status: "Trainer",

    image: team5,
  },
  {
    id: 6,
    name: "Shanice",
    status: "Trainer",

    image: team6,
  },
];
