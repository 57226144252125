import React from 'react'
import './Loader.css'
const Loader = () => {
  return (
    <section className='loader-section'>

    <div className='loader'>

       <h2 data-text="FLEX GYM...">FLEX GYM...</h2>
    </div>
    </section>

  )
}

export default Loader